import React from 'react';
import { Layout, Carousel, Image } from 'antd';
import './index.scss'

import img1 from '../../assets/1288.jpg'
import img2 from '../../assets/hj.jpg'
import img3 from '../../assets/xh.jpg'
import img4 from '../../assets/lake.jpg'
import img5 from '../../assets/tree.jpg'

function Home() {

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Layout.Header className="header">
                欢迎进入花卷记！
            </Layout.Header>
            <Layout.Content className="content" style={{ paddingTop: '10px' }}>
                <Carousel autoplay autoplaySpeed={2000}>
                    <div className='carousel-item'>
                        <Image className='img'
                            height={'100%'}
                            src={img1}
                        />
                    </div>
                    <div className='carousel-item'>
                        <Image className='img'
                            height={'100%'}
                            src={img2}
                        />
                    </div>
                    <div className='carousel-item'>
                        <Image className='img'
                            height={'100%'}
                            src={img3}
                        />
                    </div>
                    <div className='carousel-item'>
                        <Image className='img'
                            height={'100%'}
                            src={img4}
                        />
                    </div>
                    <div className='carousel-item'>
                        <Image className='img'
                            height={'100%'}
                            src={img5}
                        />
                    </div>
                </Carousel>
            </Layout.Content>
            <Layout.Footer className="footer">
                <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2024070157号-1</a> 花卷记 ©{new Date().getFullYear()} Created by 华钢
            </Layout.Footer>
        </Layout>
    )
}

export default Home;